<template>
  <div class="mt-10 mb-5 px-2 sm:px-6 lg:px-2">
    <div class="px-4 sm:px-6 lg:px-8" style="margin: auto; max-width: 1200px">
      <!-- Header -->
      <div class="flex">
        <div class="flex-1">
          <div class="pt-0 text-2xl font-medium">
            <div class="">Tags</div>
            <div class="text-xs pt-1 font-normal text-gray-500">Tags help you to categorize conversations and prioritize them. You can also use tags to search tickets & analyze reports for given tags.</div>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="onAddTagButtonClick">Add Tag</el-button>
        </div>
      </div>

      <!-- Loader -->
      <div class="mt-6 rounded-lg border overflow-hidden bg-white" v-if="loading" style="padding: 30vh 0px">
        <div v-loading="true" class="w-full h-14 mt-6"></div>
      </div>

      <!-- No Tags Found -->
      <div v-else-if="tagList.length == 0" class="mt-6 rounded-lg border overflow-hidden bg-white" style="padding: 30vh 0px">
        <div class="w-full h-14 mt-6 text-center text-gray-500">No Tags Found</div>
      </div>

      <!-- Tag List -->
      <div class="mt-6" v-else>
        <div class="flex my-2 p-3 border-b bg-white border rounded-md overflow-hidden" v-for="(tag, index) in tagList" :key="index">
          <div class="flex-1">
            <i class="fa-solid fa-circle" :style="{ color: tag.color }"></i>
            <span class="text-lg ml-1">{{ tag.name }}</span>
          </div>
          <div>
            <el-button type="primary" size="small" icon="el-icon-edit" @click="onEditTag(tag)" circle plain></el-button>
            <el-button type="danger" size="small" icon="el-icon-delete" @click="onDeleteTagButtonClick(tag)" circle plain></el-button>
          </div>
        </div>
      </div>
    </div>

    <CreateTagPopupComponent ref="createTagPopup" @onTagSaved="fetchTagsList"></CreateTagPopupComponent>
  </div>
</template>

<script>
import companySettingService from './../../../services/companySettingService';
import CreateTagPopupComponent from './createTagPopup';
export default {
  name: 'TagListComponent',
  components: {
    CreateTagPopupComponent
  },
  data() {
    return {
      loading: false,
      tagList: []
    };
  },
  computed: {},
  methods: {
    async fetchTagsList() {
      try {
        this.loading = true;
        const response = await companySettingService.getTagsList();
        this.tagList = response.data ? JSON.parse(response.data) : [];

        // Order By Name.
        this.tagList = this.tagList.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onAddTagButtonClick() {
      this.$refs.createTagPopup.show();
    },

    onEditTag(tag) {
      this.$refs.createTagPopup.show(tag);
    },

    onDeleteTagButtonClick(tag) {
      this.$confirm(`Are you sure you want to delete tag "${tag.name}"?`, 'Delete Tag', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          try {
            this.deleteTag(tag);
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },

    async deleteTag(tag) {
      try {
        // Get Existing Tags List.
        let getExistingTagList = await companySettingService.getTagsList();
        let tagList = [];
        if (getExistingTagList.data) {
          tagList = JSON.parse(getExistingTagList.data);
        }

        // Remove Tag.
        tagList = tagList.filter((t) => t.id != tag.id);

        // Save Tags List.
        await companySettingService.saveTagsList(JSON.stringify(tagList));

        this.successToast('Tag removed successfully.');
        await this.fetchTagsList();
      } catch (error) {
        console.log(error);
        this.errorToast('Failed to delete tag. Please contact support.');
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchTagsList();
  },
  destroyed() {}
};
</script>

<style></style>
