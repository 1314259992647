<template>
  <el-dialog append-to-body custom-class="createCustomAutoReplyPopup" :title="isEditMode ? 'Update Tag' : 'Create Tag'" :visible.sync="dialogVisible" width="500px">
    <div class="mx-7 mt-7">
      <el-form ref="createTagForm" :model="form" label-width="120px" label-position="top">
        <el-form-item label="Name" prop="name" :required="true">
          <el-input v-model="form.name" placeholder="Enter Tag Name"></el-input>
        </el-form-item>

        <el-form-item label="Color">
          <el-color-picker v-model="form.color"></el-color-picker>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer">
      <div class="text-right w-full">
        <el-button size="medium" type="primary" :loading="savingChanges" @click="onValidateAndSave"> {{ isEditMode ? 'Save Changes' : 'Create Tag' }} </el-button>
        <el-button size="medium" plain type="danger" @click="onCancel">Cancel</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import companySettingService from './../../../services/companySettingService';
export default {
  name: 'CreateTagPopupComponent',
  data() {
    return {
      dialogVisible: false,
      isEditMode: false,

      id: null,
      form: {
        name: null,
        color: null
      },

      savingChanges: false
    };
  },
  computed: {},
  methods: {
    async show(existing) {
      if (existing) {
        this.isEditMode = true;

        this.id = existing.id;
        this.form.name = existing.name;
        this.form.color = existing.color;
      } else {
        this.isEditMode = false;
        this.id = null;
        this.form.name = null;
        this.form.color = null;
      }
      this.dialogVisible = true;
    },

    onCancel() {
      this.dialogVisible = false;
    },

    onValidateAndSave() {
      this.$refs.createTagForm.validate((valid) => {
        console.log('Valid', valid);
        if (valid) {
          //  Validate color.
          if (!this.form.color) {
            this.errorToast('Please select a color');
            return false;
          }
          this.onSave();
          return true;
        } else {
          return false;
        }
      });
    },

    async onSave() {
      try {
        this.savingChanges = true;

        let getExistingTagList = await companySettingService.getTagsList();
        let tagList = [];
        if (getExistingTagList.data) {
          tagList = JSON.parse(getExistingTagList.data);
        }
        console.log('Existing Tags List', tagList);

        // Check for duplicate name.
        let duplicateName = tagList.find((tag) => tag.name.toLowerCase() == this.form.name.toLowerCase());
        if (duplicateName) {
          if (this.isEditMode) {
            if (duplicateName.id != this.id) {
              this.errorToast('Tag with same name already exists.');
              return;
            }
          } else {
            this.errorToast('Tag with same name already exists.');
            return;
          }
        }

        // Update Existing.
        if (this.isEditMode) {
          let existingTag = tagList.find((tag) => tag.id == this.id);
          existingTag.name = this.form.name;
          existingTag.color = this.form.color;
        }

        // Create New.
        else {
          let newTag = {
            id: this.generateUUID(),
            name: this.form.name,
            color: this.form.color
          };
          tagList.push(newTag);
        }

        console.log('New Tags List To Save', tagList);

        await companySettingService.saveTagsList(JSON.stringify(tagList));

        this.successToast('Tag saved successfully.');
        this.$emit('onTagSaved');

        this.dialogVisible = false;
      } catch (error) {
        console.log(error);
        this.errorToast('Failed to save tag. Please contact support.');
      } finally {
        this.savingChanges = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.createCustomAutoReplyPopup {
}
</style>
